import { auth, database } from "../services/firebase"
import { useEffect } from "react"
import { useState } from "react"
import { createContext, ReactNode } from "react"
import { useHistory } from 'react-router-dom'

type UserSession = {
  id: string | null;
  name: string | null;
  email: string | null;
  profile: string | null;
}
type AuthContextType = {
  userSession: UserSession | undefined;
  //signInWithGoogle: () => Promise<void>;
}
type AuthContextProviderProps = {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthContextProvider(props: AuthContextProviderProps) {

  const history = useHistory();

  const [userSession, setUserSession] = useState<UserSession>();

  //console.log("AuthContext > current data : ", userSession);

  //reloading user auth information when the screen is reloaded
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        //console.log("AuthContext > AuthStateChanged !!!", firebaseUser.uid);
        //const { uid, displayName, email } = firebaseUser;
        const { uid } = firebaseUser;

        //if localstorage is supported
        if (typeof (Storage) !== "undefined") {
          //if session is not empty
          if (sessionStorage.length !== 0) {
            //if uid is equal to localstorage
            if (sessionStorage.getItem('id') === uid) {
              //console.log("AuthContext > nothing has changed ");
              setUserSession({
                id: sessionStorage.getItem('id'),
                name: sessionStorage.getItem('name'),
                email: sessionStorage.getItem('email'),
                profile: sessionStorage.getItem('profile'),
              });
            } else {
              //console.log("AuthContext > localstorage is different, update data");
              getUserData(uid);
            }
          }
          else {
            //console.log("AuthContext > localstorage is empty, get new data");
            getUserData(uid);
          }

        } else {
          alert('Sorry! No Web Storage support..');
        }

      } else {
        //setUserSession(undefined)
        //console.log("AuthContext > back to login");        
        history.push('/login');
      }
    });

    //unsubscribing from event listener (good practice)
    return () => {
      unsubscribe();
    }
  }, []);

  function getUserData(uid: string) {

    //console.log("AuthContext > getting data ?");
    const userRef = database.ref('auth/' + uid);
    userRef.once('value')
      .then((snapshot) => {
        const databaseUser = snapshot.val();
        //console.log("getUserData > ", databaseUser);
        sessionStorage.setItem("id", databaseUser.uid);
        sessionStorage.setItem("name", databaseUser.name);
        sessionStorage.setItem("email", databaseUser.email);
        sessionStorage.setItem("profile", databaseUser.profile);
        setUserSession({
          id: databaseUser.uid,
          name: databaseUser.name,
          email: databaseUser.email,
          profile: databaseUser.profile,
        });
        //console.log("userSession > ", userSession);
      })

  }

  return (
    <AuthContext.Provider value={{ userSession }}>
      {props.children}
    </AuthContext.Provider>
  )

}