import '../styles/tables.scss'
import '../styles/search.scss'
import { useClientTable } from '../hooks/useClientTable'
import { useState, useMemo } from 'react'
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';

type ClientType = {
    id: string;
    name: string;
    email: string;
    password: string;
    profile: string;
    cnpj: string;
    address: string;
    contact: string;
}

type setClientToUpdateType = {
    setClientToUpdate: (client: ClientType) => void;
}

type columnsType = {
    Header: string;
    accessor: string;
}

export function ClientTable({ setClientToUpdate }: setClientToUpdateType) {

    //const [searchTerm, setSearchTerm] = useState('');

    const clientList = useClientTable();

    const columns: any = useMemo(() => ([
        {
            Header: 'ID',
            accessor: 'id',
            sortable: false,
            filterable: false,
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: 'Nome',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Password',
            accessor: 'password',
            sortable: false,
            filterable: false,
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: 'CNPJ',
            accessor: 'cnpj',
        },
        {
            Header: 'Endereço',
            accessor: 'address',
        },
        {
            Header: 'Contato',
            accessor: 'contact',
        },
    ]), []);

    const tableHooks = (hooks: any) => {
        hooks.visibleColumns.push((columns: columnsType[]) => [
            ...columns,
            {
                id: "Edit",
                Header: "",
                Cell: ({ row }: any) => (
                    <button onClick={() => handleUpdateClient(row.values)}><i className="far fa-edit"></i></button>
                ),
            },
        ]);
    }

    const tableInstance: any = useTable({
        columns: columns,
        data: clientList,
        initialState: { hiddenColumns: ["id", "password"] }, //pageIndex: 0
    }, tableHooks, useGlobalFilter, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setGlobalFilter,
        page,//rows,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state: { globalFilter, pageIndex, pageSize },
    } = tableInstance;

    function handleUpdateClient(client: ClientType) {
        setClientToUpdate(client);
    }

    return (
        <div className="tables">
            <h3>Consulta de Clientes</h3>
            <div className="search">
                <label htmlFor=""><b>Consultar</b></label>
                <input
                    type="text" value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                />

                <button><i className="fas fa-search"></i></button>
            </div>
            <div className="table-wrapper">
                <table id="client-table" {...getTableProps()}>
                    
                    <thead>
                        {
                            headerGroups.map((headerGroup: any) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column: any) => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted ? (column.isSortedDesc ? ' ⮟' : ' ⮝') : '  '}
                                                </span>
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {
                            page.map((row: any) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {
                                            row.cells.map((cell: any) => (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="table-footer">

                <span>
                    Página{' '}
                    <strong>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{' '}
                </span>

                <span className="divisor">{'|'}</span>

                <span>
                    <select
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        {
                            [5, 10, 15].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    mostrar {pageSize}
                                </option>
                            ))
                        }
                    </select>
                </span>

                <span className="divisor">{'|'}</span>

                <span>
                    <button className="footer-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                    <button className="footer-button" onClick={() => previousPage()} disabled={!canPreviousPage}>{' <'}</button>
                    <button className="footer-button" onClick={() => nextPage()} disabled={!canNextPage}>{' >'}</button>
                    <button className="footer-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                </span>
            </div>
        </div>
    )
}