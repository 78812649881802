import { database, firebaseSecondaryInstance, firebaseUpdateAuth } from '../services/firebase'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { userProfileList } from '../utils/userProfileList';
import '../styles/forms.scss'
import '../styles/toast.scss'
import { useState } from 'react';
import { ClientTable } from './ClientTable';

type ClientType = {
    id: string;
    name: string;
    email: string;
    password: string;
    profile: string;
    cnpj: string;
    address: string;
    contact: string;
}

export function ClientForm() {


    const [updating, setUpdating] = useState(false);

    const [clientToUpdate, setClientToUpdate] = useState<ClientType>();

    const [updatingClient, setUpdatingClient] = useState<ClientType>();

    const { register, handleSubmit, formState: { errors }, setValue, setFocus } = useForm<Omit<ClientType, 'id'>>();

    async function onSubmit(client: ClientType) {

        if (!updating) {
            //cria uma sessao secundaria para cadastrar usuario e obter id
            const secondaryInstance = firebaseSecondaryInstance();
            await secondaryInstance.auth()
                .createUserWithEmailAndPassword(client.email, client.password)
                .then(() => {
                    const createdUid = secondaryInstance.auth().currentUser?.uid
                    const newUser = {
                        uid: createdUid,
                        name: client.name,
                        email: client.email,
                        password: client.password,
                        profile: userProfileList[2], //profile: cliente
                        cnpj: client.cnpj,
                        address: client.address,
                        contact: client.contact,
                    }
                    const newAuth = { uid: createdUid, name: client.name, email: client.email, profile: userProfileList[2], }
                    const authRef = database.ref('auth/' + createdUid);
                    authRef.update(newAuth).catch((error) => { console.error("Error: ", error.message); })

                    const userRef = database.ref('clients/' + createdUid);
                    userRef.update(newUser).then(() => { //change .push to .update to have createdId as nodeId
                        handleClearForm()
                        toast.success("Cadastro realizado com sucesso!", { className: "toast-success" })
                    }).catch((error) => {
                        console.error("Error: ", error.message);
                        toast.error("Erro ao salvar cadastro")
                    })

                    secondaryInstance.auth().signOut();
                })
                .catch((error) => {
                    console.log("Error: ", error.message)
                    toast.error("Erro ao cadastrar cliente")
                })
            //atualiza usuario ja existente                
        } else {

            const clientId = updatingClient?.id;

            //console.log("ClientForm > updating: " + client.email + " " + client.password)
            //if email or pw is being updated, authenticate with a secondaryInstance
            if (updatingClient?.password !== client.password || updatingClient?.email !== client.email) {
                //console.log("updatingClient: ", updatingClient)

                if (updatingClient && client) {

                    const credentialUpdated = await firebaseUpdateAuth(clientId, userProfileList[2],
                        { email: updatingClient?.email, password: updatingClient?.password },
                        { email: client?.email, password: client?.password }
                    ).then( credentialUpdated => credentialUpdated )
                        .catch( error => { console.error("Error: ", error.message) })

                    if (credentialUpdated) {
                        //console.log("credentialUpdated: ", credentialUpdated)
                        toast.success("Email/senha atualizado com sucesso!", { className: "toast-success" });
                        handleClearForm()
                    } else {
                        //handleClearForm()
                        //console.log("credentialUpdated: ", credentialUpdated)
                        toast.error("Erro ao editar senha/email")
                    }

                }
            }

            const clientRef = database.ref(`clients/${clientId}`);
            const authRef = database.ref('auth/' + clientId);

            if (updatingClient?.name !== client.name || updatingClient?.address !== client.address
                || updatingClient?.contact !== client.contact || updatingClient?.cnpj !== client.cnpj) {

                authRef.update({ name: client.name }).catch((error) => { console.error("Error: ", error.message); })

                clientRef.update({
                    name: client.name,
                    address: client.address,
                    cnpj: client.cnpj,
                    contact: client.contact,
                }).then(() => {
                    handleClearForm()
                    toast.success("Cadastro atualizado com sucesso!", { className: "toast-success" })
                }).catch((error) => {
                    console.error("Error: ", error.message);
                    toast.error("Erro ao editar cadastro")
                })
            }

        }

    }

    function handleClearForm() {
        setValue('name', '');
        setValue('email', '');
        setValue('password', '');
        setValue('cnpj', '');
        setValue('address', '');
        setValue('contact', '');
        setUpdating(false);
        setClientToUpdate(undefined);
        //console.log("updating set false ");
    }

    if (clientToUpdate && !updating) {  //should set updating once
        const client = clientToUpdate;
        setValue('name', client.name);
        setValue('email', client.email);
        setValue('password', client.password);
        setValue('cnpj', client.cnpj);
        setValue('address', client.address);
        setValue('contact', client.contact);
        setFocus('name');
        setUpdating(true);
        setUpdatingClient(clientToUpdate);
        setClientToUpdate(undefined);
        //console.log("updating set true ");
    }

    //console.log("updating: ", updating);

    // function handleCnpjMask(cnpj: string) {
    //     console.log(cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"));
    //     return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    //     //return cnpj.replace(/\s/g, "").match(/.{1,4}/g).join(".").substr(0, 18);
    // }

    return (
        <div>
            <div className="forms">
                <h3>Cadastro de Clientes</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor=""><b>Nome</b></label>
                        <input type="text" placeholder="digite o nome do usuário"
                            {
                            ...register("name", { required: true, })
                            }
                        />
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.name?.type === 'required' && "nome não informado"}</strong>
                    </div>

                    <div>
                        <label htmlFor=""><b>Email</b></label>
                        <input type="email" placeholder="digite o email de acesso"
                            {
                            ...register("email", {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "email inválido"
                                }
                            })
                            }
                        />
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.email && errors.email.message}</strong>
                    </div>

                    <div>
                        <label htmlFor=""><b>Senha</b></label>
                        <input type="password" placeholder="digite uma senha"
                            {
                            ...register("password", {
                                required: true,
                                minLength: {
                                    value: 6,
                                    message: "informar pelo menos 6 caracteres"
                                }
                            })
                            }
                        />
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.password && errors.password.message}</strong>
                    </div>

                    <div>
                        <label htmlFor=""><b>CNPJ/CPF</b></label>
                        <input type="text" placeholder="99.999.999/9999-99 ou 999.999.999-99"
                            {
                            ...register("cnpj", {
                                required: true,
                                pattern: {
                                    //value: /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/,
                                    value: /(^\d{3}.?\d{3}.?\d{3}-?\d{2})|(^\d{2}.?\d{3}.?\d{3}\/\d{4}-?\d{2})/,
                                    message: "CNPJ/CPF inválido"
                                }
                            })}
                        />
                        {/*onChange={(e) => {
                            const {value} =e.target
                            e.target.value = handleCnpjMask(value);
                        }}*/}
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.cnpj && errors.cnpj.message}</strong>
                    </div>

                    <div>
                        <label htmlFor=""><b>Endereço</b></label>
                        <input type="text" placeholder="digite o endereço do cliente"
                            {
                            ...register("address", { required: false, })
                            }
                        />
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.address?.type === 'required' && "endereço não informado"}</strong>
                    </div>

                    <div>
                        <label htmlFor=""><b>Contato</b></label>
                        <input type="text" placeholder="digite um contato do cliente"
                            {
                            ...register("contact", { required: true, })
                            }
                        />
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.contact?.type === 'required' && "contato não informado"}</strong>
                    </div>

                    <div>
                        <div className="btn-container">
                            <button className="btn-save" type="submit">
                                {updating ? "Alterar" : "Cadastrar"}
                            </button>
                            <button className="btn-cancel" type="button" onClick={handleClearForm}>Limpar</button>
                        </div>

                    </div>

                </form>
            </div>
            <ClientTable setClientToUpdate={setClientToUpdate} />
        </div>
    )
}