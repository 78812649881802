import { database } from '../services/firebase'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { userProfileList } from '../utils/userProfileList';

import '../styles/forms.scss'
import '../styles/search.scss'

import { FinanceTable } from './FinanceTable';
import { useSelectedClient } from '../hooks/useSelectedClient';
import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';

type TransactionForm = {
    client: string;
    project: string;
    description: string;
    amount: string;
    type: string;
    paymentDate: string;
    checked: boolean;
}
type Transaction = {
    id: string,
    projectId: string;
    createdDate: Number;
    description: string;
    amount: string;
    type: string;
    paymentDate: string;
    checked: boolean;
}
type Project = {
    id: string;
    clientId: string;
    name: string;
    address: string;
    transactions?: Transaction[];
}
type ClientType = {
    id: string;
    name: string;
    email: string;
    //password: string;
    profile: string;
    cnpj: string;
    //projects: Project[];
}

interface TransactionProps {
    onOpenSelectClientModal: () => void;
    selectedClient?: ClientType;
}

export function FinanceForm({ onOpenSelectClientModal, selectedClient }: TransactionProps) {

    const { userSession } = useAuth();

    //let updating = false;
    const [updating, setUpdating] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue, setFocus } = useForm<TransactionForm>();

    const [transactionToUpdate, setTransactionToUpdate] = useState<Transaction>();

    const [updatingTransaction, setUpdatingTransaction] = useState<Transaction>();

    let currentClientId = "";
    if (userSession?.profile === userProfileList[2]) {
        currentClientId = userSession?.id ?? ""
    } else {
        currentClientId = selectedClient?.id ?? ""
    }
    const client = useSelectedClient(currentClientId)

    setValue('client', client?.name ?? "");

    const [projectOption, setProjectOption] = useState<Project>();

    function handleSelectedProjectOption(option: string) {
        setProjectOption(client?.projects.find((project, index) => project.name === option));
        setTransactionToUpdate(undefined)
    }

    if( projectOption ) {
        if( selectedClient ) {
            if( selectedClient.id !== projectOption?.clientId ) {
                //console.log("ProjectForm > client and selected project dont match !!!", )
                setProjectOption(undefined);
            }
        }
    }

    async function onSubmit(transaction: TransactionForm) {

        let newPaymentDateString = "";

        if (transaction.paymentDate) {
            let newPaymentDate = new Date(transaction.paymentDate);
            //fixing date if browser mess it up!!
            try {
                const originalPaymentDayNum = Number.parseInt(transaction.paymentDate.substr(8, 2));
                const newPaymentDayNum = Number.parseInt(new Intl.DateTimeFormat('pt-BR').format(newPaymentDate).substr(0, 2))
                if (originalPaymentDayNum === newPaymentDayNum) {
                    // console.log("same day")
                } else if (originalPaymentDayNum > newPaymentDayNum) {
                    // console.log("new day is lesser")
                    newPaymentDate = new Date(newPaymentDate.setDate(newPaymentDate.getDate() + 1))
                } else if (originalPaymentDayNum < newPaymentDayNum) {
                    // console.log("new day is greater")
                    newPaymentDate = new Date(newPaymentDate.setDate(newPaymentDate.getDate() - 1))
                }
            } catch (error) { console.log(error.message); }

            newPaymentDateString = new Intl.DateTimeFormat('pt-BR').format(newPaymentDate);
        }

        if (!updating) {

            let createDateString = new Date();
            let createDateNumber = Date.parse(createDateString.toString());

            let projectId = transaction.project;
            if (client?.projects) {
                client.projects.map((project, index) => {
                    if (project.name === transaction.project) {
                        projectId = project.id;
                    }
                })
            }
            const newTransaction: Omit<Transaction, 'id'> = {
                projectId: projectId,
                createdDate: createDateNumber,
                description: transaction.description,
                amount: transaction.amount,
                type: transaction.type,
                paymentDate: newPaymentDateString,
                checked: newPaymentDateString ? true : false,
            }
            const clientRef = database.ref(`clients/${client?.id}/projects/${projectId}/transactions`);
            clientRef.push(newTransaction).then(() => {
                handleClearForm()
                toast.success("Transação criada com sucesso!", { className: "toast-success" })
            }).catch((error) => {
                console.error("Error: ", error.message);
                toast.error("Erro ao criar transaction")
            })

        } else {
            const transactionRef = database.ref(`clients/${client?.id}/projects/${updatingTransaction?.projectId}/transactions/${updatingTransaction?.id}`);
            transactionRef.update({
                description: transaction.description,
                amount: transaction.amount,
                type: transaction.type,
                paymentDate: newPaymentDateString,
                checked: newPaymentDateString ? true : false,
            }).then(() => {
                handleClearForm()
                toast.success("Transação atualizada com sucesso!", { className: "toast-success" })
            }).catch((error) => {
                console.error("Error: ", error.message);
                toast.error("Erro ao editar transação")
            })

        }
    }

    function handleClearForm() {
        setValue('description', '');
        setValue('amount', '');
        setValue('paymentDate', '')
        //updating = false;
        setTransactionToUpdate(undefined)
        setUpdating(false);
    }

    if (transactionToUpdate && !updating) {
        //updating = true;
        setValue('description', transactionToUpdate.description)
        setValue('amount', transactionToUpdate.amount);
        setValue('type', transactionToUpdate.type);
        setValue('paymentDate', transactionToUpdate.paymentDate);
        setFocus('description');
        setUpdating(true);
        setUpdatingTransaction(transactionToUpdate);
        setTransactionToUpdate(undefined)
    }
    if (selectedClient && !updating && !projectOption) {
        setValue('project', "")
    }

    return (
        <div>
            {/* {console.log("rendering form...")} */}
            <div className="forms">
                <h3>Selecionar Cliente</h3>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="search-form">
                        <label htmlFor=""><b>Cliente</b></label>
                        {(userSession?.profile === userProfileList[0] || userSession?.profile === userProfileList[1])
                            ?
                            <input type="text" placeholder="selecione um cliente" readOnly={true}
                                onClick={onOpenSelectClientModal}
                                {
                                ...register("client", { required: true, })
                                }
                            />
                            :
                            <input type="text" placeholder="selecione um cliente" readOnly={true}
                                {
                                ...register("client", { required: true, })
                                }
                            />
                        }
                        <button
                            hidden={userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]}
                            type="button"
                            onClick={onOpenSelectClientModal}
                        ><i className="fas fa-search"></i></button>
                    </div>

                    <div className="error-message">
                        <span></span><strong>{errors.client?.type === 'required' && "cliente não informado"}</strong>
                    </div>

                    <div className="search-form">
                        <label htmlFor="profile"><b>Projetos</b></label>
                        <select
                            {
                            ...register("project", {
                                required: true,
                                pattern: {
                                    value: /^(?!\s*$).+/,
                                    message: "selecionar um projeto"
                                }
                            })}
                            onChange={(e) => handleSelectedProjectOption(e.target.value)}
                        >
                            <option value=""> -- selecionar -- </option>
                            {
                                client?.projects
                                    ? client.projects.map((project, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={project.name}
                                            >
                                                {project.name}
                                            </option>
                                        )
                                    })
                                    : console.log("no projects available")
                            }
                        </select>
                        <button type="button" hidden>
                            {projectOption?.id ? <i className="far fa-edit"></i> : <i className="far fa-plus-square"></i>}
                        </button>
                    </div>

                    <div className="error-message">
                        <span></span><strong>{errors.project?.type === 'required' && "projeto não informado"}</strong>
                    </div>

                    {/*-----------------INSERIR TRANSACAO--------------------------------------------------------------------------------*/}
                    {(userSession?.profile === userProfileList[0])
                        ? <>
                            <h3>Inserir Transação</h3>
                            <div>
                                <label htmlFor=""><b>Descrição</b></label>
                                <input type="text" placeholder="digite uma descrição para a transação"
                                    {
                                    ...register("description", { required: true, })
                                    }
                                />
                            </div>
                            <div className="error-message">
                                <span></span>
                                <strong>{errors.description?.type === 'required' && "transação não informada"}</strong>
                            </div>

                            <div>
                                <label htmlFor=""><b>Valor</b></label>
                                <input type="number" placeholder="0" step="any"
                                    {
                                    ...register("amount", {
                                        required: true,
                                        minLength: {
                                            value: 0,
                                            message: "valor não informado"
                                        }
                                    })
                                    }
                                />
                            </div>
                            <div className="error-message">
                                <span></span>
                                <strong>{errors.amount?.type === 'required' && "valor não informado"}</strong>
                            </div>

                            <div>
                                <label htmlFor="" ><b>Tipo</b></label>
                                <div className="radio-group">

                                    <input type="radio" id="deposit" value="deposit"
                                        //if tranctionToUpdate is undefined, set true to deposit, else check for updating type
                                        //checked={ transactionToUpdate?.type ? transactionToUpdate?.type === 'deposit' : true } 
                                        {...register("type", { required: true })} />
                                    <label htmlFor="deposit">Entrada</label>

                                    <input type="radio" id="withdraw" value="withdraw"
                                        //if tranctionToUpdate is undefined, set false to withdraw, else check for updating type
                                        //checked={ transactionToUpdate?.type ? transactionToUpdate?.type === 'withdraw' : false }
                                        {...register("type", { required: true })} />
                                    <label htmlFor="withdraw">Saída</label>
                                </div>
                            </div>

                            <div className="error-message">
                                <span></span>
                                <strong>{errors.type?.type === 'required' && "tipo não informado"}</strong>
                            </div>

                            <div>
                                <label htmlFor=""><b>Pagamento</b></label>
                                <input id="paymentday-transaction" type="date" placeholder="digite o nome do usuário"
                                    {
                                    ...register("paymentDate", { required: false, })
                                    }
                                />

                            </div>
                            <div className="error-message">
                                <span></span>
                                <strong>{errors.paymentDate?.type === 'required' && "prazo não informado"}</strong>
                            </div>

                            <div>
                                <div className="btn-container">
                                    <button className="btn-save" type="submit">
                                        {updating ? "Alterar" : "Cadastrar"}
                                    </button>
                                    <button className="btn-cancel" type="button" onClick={handleClearForm}>Limpar</button>
                                </div>

                            </div>
                            <div className="error-message">
                                <span></span><strong></strong>
                            </div>
                        </>
                        : ""
                    }
                </form>
            </div>

            <FinanceTable
                selectedProjectId={projectOption?.id}
                selectedClientId={projectOption?.clientId}
                setTransactionToUpdate={setTransactionToUpdate}
            />
        </div>
    )
}