import { database, firebaseSecondaryInstance, firebaseUpdateAuth } from '../services/firebase'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { userProfileList } from '../utils/userProfileList';
import '../styles/forms.scss'
import '../styles/toast.scss'
import { useState } from 'react';
import { UserTable } from './UserTable';

type UserType = {
    id: string;
    name: string;
    email: string;
    password: string;
    profile: string;
}
export function UserForm() {

    const [updating, setUpdating] = useState(false);

    const [userToUpdate, setUserToUpdate] = useState<UserType>();

    const [updatingUser, setUpdatingUser] = useState<UserType>();

    const { register, handleSubmit, formState: { errors }, setValue, setFocus } = useForm<Omit<UserType, 'id'>>();

    async function onSubmit(user: UserType) {

        if (!updating) {
            //cria uma sessao secundaria para cadastrar usuario e obter id
            const secondaryInstance = firebaseSecondaryInstance();
            await secondaryInstance.auth()
                .createUserWithEmailAndPassword(user.email, user.password)
                .then(() => {
                    const createdUid = secondaryInstance.auth().currentUser?.uid
                    const newUser = {
                        uid: createdUid,
                        name: user.name,
                        email: user.email,
                        password: user.password,
                        profile: user.profile,
                    }
                    const newAuth = { uid: createdUid, name: user.name, email: user.email, profile: user.profile, }
                    const authRef = database.ref('auth/' + createdUid);
                    authRef.update(newAuth).catch((error) => { console.error("Error: ", error.message); })

                    const userRef = database.ref('users/' + createdUid);
                    userRef.update(newUser).then(() => { //change .push to .update to have createdId as nodeId
                        handleClearForm()
                        toast.success("Cadastro realizado com sucesso!", { className: "toast-success" })
                    }).catch((error) => {
                        console.error("Error: ", error.message);
                        toast.error("Erro ao salvar cadastro")
                    })
                    secondaryInstance.auth().signOut();
                })
                .catch((error) => {
                    console.log("Error: ", error.message)
                    toast.error("Erro ao cadastrar usuário")
                })
            //atualiza usuario ja existente                
        } else {
            const userId = updatingUser?.id;
            //if email or pw is being updated, authenticate with a secondaryInstance
            if (updatingUser?.password !== user.password || updatingUser?.email !== user.email) {

                if (updatingUser && user) {

                    const credentialUpdated = await firebaseUpdateAuth(
                        userId, updatingUser.profile,
                        { email: updatingUser.email, password: updatingUser.password },
                        { email: user.email, password: user.password }
                    ).then(credentialUpdated => credentialUpdated)
                        .catch(error => { console.error("Error: ", error.message) })
                    
                        if (credentialUpdated) {
                        //console.log("credentialUpdated: ", credentialUpdated)
                        toast.success("Email/senha atualizado com sucesso!", { className: "toast-success" });
                        handleClearForm()
                    } else {
                        //handleClearForm()
                        //console.log("credentialUpdated: ", credentialUpdated)
                        toast.error("Erro ao editar senha/email")
                    }
                }

            }

            const userRef = database.ref(`users/${userId}`);
            const authRef = database.ref('auth/' + userId);

            if (updatingUser?.name !== user.name || updatingUser?.profile !== user.profile) {

                authRef.update({
                    name: user.name,
                    profile: user.profile
                }).catch((error) => { console.error("Error: ", error.message); })

                userRef.update({ //email and pw were saved above if value was changed 
                    name: user.name,
                    profile: user.profile,
                }).then(() => {
                    handleClearForm()
                    toast.success("Cadastro atualizado com sucesso!", { className: "toast-success" })
                }).catch((error) => {
                    console.error("Error: ", error.message);
                    toast.error("Erro ao editar cadastro")
                })
            }

        }

    }

    function handleClearForm() {
        setValue('name', '');
        setValue('email', '');
        setValue('password', '');
        setValue('profile', '');
        setUpdating(false);
        setUserToUpdate(undefined);
        //console.log("updating set false ");
    }

    if (userToUpdate && !updating) {  //should set updating once
        const user = userToUpdate;
        setValue('name', user.name);
        setValue('email', user.email);
        setValue('password', user.password);
        setValue('profile', user.profile);
        setFocus('name');
        setUpdating(true);
        setUpdatingUser(userToUpdate);
        setUserToUpdate(undefined);
        //console.log("updating set true ");
    }

    //console.log("updating: ", updating);

    return (
        <div>

            <div className="forms">
                {/* {console.log("rendering form...")} */}
                <h3>Cadastro de Usuários</h3>
                {/*noValidate*/}
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div>
                        <label htmlFor=""><b>Nome</b></label>
                        <input type="text" placeholder="digite o nome do usuário"
                            {
                            ...register("name", { required: true, })
                            }
                        />

                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.name?.type === 'required' && "nome não informado"}</strong>
                    </div>

                    <div>
                        <label htmlFor=""><b>Email</b></label>
                        <input type="email" placeholder="digite o email de acesso"
                            {
                            ...register("email", {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "email inválido"
                                }
                            })
                            }
                        />
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.email && errors.email.message}</strong>
                    </div>

                    <div>
                        <label htmlFor=""><b>Senha</b></label>
                        <input type="password" placeholder="digite uma senha"
                            {
                            ...register("password", {
                                required: true,
                                minLength: {
                                    value: 6,
                                    message: "informar pelo menos 6 caracteres"
                                }
                            })
                            }
                        />

                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.password && errors.password.message}</strong>
                    </div>

                    <div>
                        <label htmlFor="profile"><b>Perfil</b></label>
                        <select {
                            ...register("profile", {
                                required: true,
                                pattern: {
                                    value: /^(?!\s*$).+/,
                                    message: "selecionar um perfil"
                                }
                            })}
                        >
                            <option value=""> -- selecionar -- </option>
                            <option value={userProfileList[0]}>Administrador</option>
                            <option value={userProfileList[1]}>Gerente</option>
                        </select>
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.profile && errors.profile.message}</strong>
                    </div>

                    <div>
                        <div className="btn-container">
                            <button className="btn-save" type="submit">
                                {updating ? "Alterar" : "Cadastrar"}
                            </button>
                            <button className="btn-cancel" type="button" onClick={handleClearForm}>Limpar</button>
                        </div>

                    </div>

                </form>
            </div>

            <UserTable setUserToUpdate={setUserToUpdate} />
        </div>

    )
}