import '../styles/modal.scss'
import closeImg from '../assets/close.svg'
import { toast } from 'react-toastify';
import Modal from 'react-modal'
import { database } from '../services/firebase'
import { useForm } from 'react-hook-form';

interface SelectProjectModalProps {
    isOpen: boolean;
    selectedProject: Project | undefined;
    clientId?: string;
    clientName?: string;
    onRequestClose: () => void;
    //setSelectedClient: (client: ClientType) => void;
}

type Project = {
    id: string;
    clientId: string;
    name: string;
    address: string;
}

export function CreateProjectModal({ isOpen, selectedProject, clientId, clientName, onRequestClose }: SelectProjectModalProps) {

    let updating = false;

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<Project>();

    //console.log("selected project: ", selectedProject);
    if(selectedProject) {
        setValue('name', selectedProject.name);
        setValue('address', selectedProject.address);
        updating = true;
    } else {
        setValue('name', '');
        setValue('address', '');
    }

    async function onSubmit(project: Project) {
        //console.log(project)

        if (!updating) {
            
            const newProject = {
                clientId: clientId,
                name: project.name,
                address: project.address,
            }
            
            const clientRef = database.ref(`clients/${clientId}/projects`);
            clientRef.push(newProject).then(() => {
                handleClearForm()
                onRequestClose();
                toast.success("Projeto criado com sucesso!", {className: "toast-success"})
            }).catch((error) => {
                console.error("Error: ", error.message);
                toast.error("Erro ao criar projeto")
            })
            
        } else {
            //const clientRef = database.ref(`clients/${clientId}/projects/${projectId}`);
            const clientProjectRef = database.ref(`clients/${clientId}/projects/${selectedProject?.id}`);
            clientProjectRef.update({
                name: project.name,
                address: project.address,
            }).then(() => {
                handleClearForm()
                toast.success("Projeto atualizado com sucesso!", {className: "toast-success"})
            }).catch((error) => {
                console.error("Error: ", error.message);
                toast.error("Erro ao editar projeto")
            })

        }

    }

    function handleClearForm() {
        setValue('name', '');
        setValue('address', '');
        updating = false;
        if(selectedProject) {
            onRequestClose();
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <div className="react-modal-form">

                <button className="react-modal-close" type="button" onClick={onRequestClose}>
                    <img src={closeImg} alt="Fechar modal" />
                </button>


                <h3>{updating ? "Alterar" : "Cadastrar"} Projeto</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                   <div>
                        <label htmlFor=""><b>Cliente</b></label>
                        <input type="text" readOnly={true} value={clientName}/>
                    </div>
                    <div className="error-message">
                        <span></span><strong></strong>
                    </div>

                    <div>
                        <label htmlFor=""><b>Nome</b></label>
                        <input type="text" placeholder="digite um nome para o projeto"
                            {
                            ...register("name", { required: true, })
                            }
                        />
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.name?.type === 'required' && "nome não informado"}</strong>
                    </div>

                    <div>
                        <label htmlFor=""><b>Endereço</b></label>
                        <input type="text" placeholder="digite o endereço do projeto"
                            {
                            ...register("address", { required: true, })
                            }
                        />
                    </div>

                    <div className="error-message">
                        <span></span>
                        <strong>{errors.address?.type === 'required' && "nome não informado"}</strong>
                    </div>

                    <div>
                        <div className="btn-container">
                            <button className="btn-save" type="submit">
                                {updating ? "Alterar" : "Cadastrar"}
                            </button>
                            <button className="btn-cancel" type="button" onClick={handleClearForm}>
                                {updating ? "Cancelar" : "Limpar"}
                            </button>
                        </div>

                    </div>

                </form>
            </div>

        </Modal>
    )
}