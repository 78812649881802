import '../styles/modal.scss'
import Modal from 'react-modal'
import closeImg from '../assets/close.svg'
import { useForm } from 'react-hook-form';
import { auth } from '../services/firebase'
import { toast } from 'react-toastify';

interface RecoverPasswordModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
}
type RecoverType = {
    email: string;
}

export function RecoverPasswordModal({ isOpen, onRequestClose }: RecoverPasswordModalProps) {

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<RecoverType>();

    function onSubmit(sendEmailAddress: RecoverType) {
        if (sendEmailAddress.email) {
            auth.sendPasswordResetEmail(sendEmailAddress.email).then(function () {
                toast.success("Link para recuperação de senha enviado!", { className: "toast-success" })
            }).then(() => { 
                handleClearForm() 
            })
            .catch(function (error) {
                if (error.code === 'auth/invalid-email') {
                    toast.error('E-mail Inválido');
                } else if (error.code === 'auth/user-not-found') {
                    toast.error('Usuário não encontrado');
                } else {
                    toast.error("Erro ao enviar email")
                }
                console.log("Ocorreu um erro... ", error.message);
            });
        }
    }

    function handleClearForm() {
        setValue('email', '');
        onRequestClose();
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-remember"
        >
            <div className="react-modal-form">
                <button className="react-modal-close" type="button" onClick={onRequestClose}>
                    <img src={closeImg} alt="Fechar modal" />
                </button>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id="react-modal-simple-form">
                        <p>Para recuperar sua senha, informe seu email cadastrado
                            que enviaremos um link para alteração da senha:</p>
                        <input type="email" placeholder="digite o email de acesso"
                            {
                            ...register("email", { required: true, })
                            }
                        />

                        <div className="btn-container">
                            <button className="btn-save" type="submit">
                                Enviar
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </Modal>
    )
}