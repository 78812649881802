import { Link } from 'react-router-dom'
import { userProfileList } from '../utils/userProfileList';
import '../styles/sidebar.scss'
import { useAuth } from '../hooks/useAuth';
import { useState } from 'react';

export function Sidebar() {

    const { userSession } = useAuth();
    //const userProfile = sessionStorage.getItem('profile')
    const userProfile = userSession?.profile ? userSession?.profile : sessionStorage.getItem('profile');

    const [menuToggle, setMenuToggle] = useState(false);

    function handleMenuToggle(cameFromLink: boolean) {
        setMenuToggle(cameFromLink ? false : !menuToggle);
    }

    return (
        <div className={`sidebar-position ${menuToggle ? "on" : ""}`}>
            <div className="menu-toggle" onClick={() => { handleMenuToggle(false) }} >
                <div className="one"></div>
                <div className="two"></div>
                <div className="three"></div>
            </div>
            {userProfile === userProfileList[0] || userProfile === userProfileList[1]
                ?
                <div className="sidebar" onClick={() => { handleMenuToggle(true) }}>

                    {userProfile === userProfileList[0]
                        ?
                        <Link to='/usuarios'>
                            <i className="fas fa-users"></i>
                            <div className="tooltip">
                                <span className="tooltiptext">Usuários</span>
                            </div>
                        </Link>
                        : ""}

                    <Link to='/clientes'>
                        <i className="fas fa-user-tie"></i>
                        <div className="tooltip">
                            <span className="tooltiptext">Clientes</span>
                        </div>
                    </Link>
                    <Link to='/projetos'>
                        <i className="fab fa-product-hunt"></i>
                        <div className="tooltip">
                            <span className="tooltiptext">Projetos</span>
                        </div>
                    </Link>
                    <Link to='/financeiro'>
                        <i className="fas fa-dollar-sign"></i>
                        <div className="tooltip">
                            <span className="tooltiptext">Financeiro</span>
                        </div>
                    </Link>
                    <Link to='/institucional'>
                        <i className="fas fa-info-circle"></i>
                        <div className="tooltip">
                            <span className="tooltiptext">Institucional</span>
                        </div>
                    </Link>
                </div>
                :
                <div className="sidebar">
                    <Link to='/projetos'>
                        <i className="fab fa-product-hunt"></i>
                        <div className="tooltip">
                            <span className="tooltiptext">Projetos</span>
                        </div>
                    </Link>
                    <Link to='/financeiro'>
                        <i className="fas fa-dollar-sign"></i>
                        <div className="tooltip">
                            <span className="tooltiptext">Financeiro</span>
                        </div>
                    </Link>
                    <Link to='/institucional'>
                        <i className="fas fa-info-circle"></i>
                        <div className="tooltip">
                            <span className="tooltiptext">Institucional</span>
                        </div>
                    </Link>
                </div>
            }

        </div>
    )
}