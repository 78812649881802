import '../styles/tables.scss'
import '../styles/search.scss'
import { useUserTable } from '../hooks/useUserTable'
import { useState, useMemo, useEffect } from 'react'
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';

type UserType = {
    id: string;
    name: string;
    email: string;
    password: string;
    profile: string;
}

type setUserToUpdateType = {
    setUserToUpdate: (user: UserType | undefined) => void;
}

type columnsType = {
    Header: string;
    accessor: string;
}

export function UserTable({ setUserToUpdate }: setUserToUpdateType) {

    //const [searchTerm, setSearchTerm] = useState('');

    const userList = useUserTable();

    //console.log("first render > ", userList)
    // useEffect(() => {
    // }, [userList])

    const columns: any = useMemo(() => ([
        {
            Header: 'ID',
            accessor: 'id',
            sortable: false,
            filterable: false,
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: 'Nome',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Password',
            accessor: 'password',
            sortable: false,
            filterable: false,
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: 'Perfil',
            accessor: 'profile',
        },
    ]), []);

    // const usersData = userList.map((user) => {
    //     return {
    //         id: user.id,
    //         name: user.name,
    //         email: user.email,
    //         profile: user.profile,
    //     }
    // });

    //const data = useMemo(() => usersData, []);
    // const [data, setData] = useState<Omit<UserType, 'password'>[]>();
    // setData(usersData);

    const tableHooks = (hooks: any) => {
        hooks.visibleColumns.push((columns: columnsType[]) => [
            ...columns,
            {
                id: "Edit",
                Header: "",
                Cell: ({ row }: any) => (
                    <button onClick={() => handleUpdateUser(row.values)}><i className="far fa-edit"></i></button>
                ),
            },
        ]);
    }

    const tableInstance: any = useTable({
        columns: columns,
        data: userList,
        initialState: { hiddenColumns: ["id", "password"] }, //pageIndex: 0
    }, tableHooks, useGlobalFilter, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setGlobalFilter,
        page,//rows,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state: { globalFilter, pageIndex, pageSize },
    } = tableInstance;

    function handleUpdateUser(user: UserType) {
        setUserToUpdate(user);
    }

    //const { globalFilter }: any = state

    return (
        <div className="tables">
            <h3>Consulta de Usuários</h3>
            <div className="search">
                <label htmlFor=""><b>Consultar</b></label>
                {/*<input type="text" placeholder="digite um nome ou email"
                    onChange={(event) => {
                        setSearchTerm(event.target.value)
                    }}
                />*/}

                <input
                    type="text" value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                />

                <button><i className="fas fa-search"></i></button>
            </div>
            <div className="table-wrapper">
                <table id="user-table" {...getTableProps()}>
                    <thead>
                        {
                            headerGroups.map((headerGroup: any) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column: any) => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted ? (column.isSortedDesc ? ' ⮟' : ' ⮝') : '  '}
                                                </span>
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {
                            page.map((row: any) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {
                                            row.cells.map((cell: any) => (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="table-footer">

                <span>
                    Página{' '}
                    <strong>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{' '}
                </span>

                <span className="divisor">{'|'}</span>
                        
                {/*
                <span>
                    Ir: {'  '}
                    <input min="1" max={pageOptions.length}
                        type="number" defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(pageNumber);
                        }}
                    />
                </span>
                <span className="divisor">{'|'}</span>
                 */}

                <span>
                    <select
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                        {
                            [5, 10, 15].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    mostrar {pageSize}
                                </option>
                            ))
                        }
                    </select>
                </span>

                <span className="divisor">{'|'}</span>

                <span>
                    <button className="footer-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                    <button className="footer-button" onClick={() => previousPage()} disabled={!canPreviousPage}>{' <'}</button>
                    <button className="footer-button" onClick={() => nextPage()} disabled={!canNextPage}>{' >'}</button>
                    <button className="footer-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                </span>
            </div>

            {/*
                    pageOptions.map((item: any) => (
                        item < 5 
                        ? <button onClick={() => gotoPage(Number(item))}>{item+1}</button> 
                        : ''
                    ))
                    <button disabled={true}>...</button>
            */}


        </div>
    )
}