import '../styles/modal.scss'
import '../styles/search.scss'
import closeImg from '../assets/close.svg'
import Modal from 'react-modal'
import { useState, useMemo } from 'react';
import { useClientTable } from '../hooks/useClientTable';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';

interface SelectClientModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    setSelectedClient: (client: ClientType) => void;
}

type ClientType = {
    id: string;
    name: string;
    email: string;
    //password: string;
    profile: string;
    cnpj: string;
    //projects: Project[];
}

type columnsType = {
    Header: string;
    accessor: string;
}

export function SelectClientModal({ isOpen, onRequestClose, setSelectedClient }: SelectClientModalProps) {

    //const [searchTerm, setSearchTerm] = useState('');

    const clientList = useClientTable();

    const columns: any = useMemo(() => ([
        {
            Header: 'ID',
            accessor: 'id',
            sortable: false,
            filterable: false,
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: 'Nome',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'CNPJ',
            accessor: 'cnpj',
        },
    ]), []);

    const tableInstance: any = useTable({
        columns: columns,
        data: clientList,
        initialState: {
            hiddenColumns: ["id", "password"], 
        },
        //initialState: { pageIndex: 0, pageSize: 10 },
    }, useGlobalFilter, useSortBy, usePagination);
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setGlobalFilter,
        page,//rows,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state: { globalFilter, pageIndex, pageSize },
    } = tableInstance;

    function handleSelectedClient(client: ClientType) {
        console.log("cliente selecionado: ", client)
        //setSearchTerm("");
        setSelectedClient(client);
        onRequestClose();
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <div className="react-modal-table">
                <button className="react-modal-close" type="button" onClick={onRequestClose}>
                    <img src={closeImg} alt="Fechar modal" />
                </button>

                <div className="search">
                    <label htmlFor=""><b>Consultar</b></label>

                    <input
                        type="text" value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                    />

                    <button><i className="fas fa-search"></i></button>
                </div>

                <div className="table-wrapper">
                    <table {...getTableProps()}>
                        <thead>
                            {
                                headerGroups.map((headerGroup: any) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map((column: any) => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render('Header')}
                                                    <span>
                                                        {column.isSorted ? (column.isSortedDesc ? ' ⮟' : ' ⮝') : '  '}
                                                    </span>
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>

                        <tbody {...getTableBodyProps()}>
                            {
                                page.map((row: any) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()} onClick={() => { handleSelectedClient(row.values) }}>
                                            {
                                                row.cells.map((cell: any) => (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <div className="table-footer">

                    <span>
                        Página{' '}
                        <strong>
                            {pageIndex + 1} de {pageOptions.length}
                        </strong>{' '}
                    </span>

                    {/*
                    <span className="divisor">{'|'}</span>

                    <span>
                        <select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                        >
                            {
                                [5, 10, 15].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        mostrar {pageSize}
                                    </option>
                                ))
                            }
                        </select>
                    </span> */}

                    <span className="divisor">{'|'}</span>

                    <span>
                        <button className="footer-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                        <button className="footer-button" onClick={() => previousPage()} disabled={!canPreviousPage}>{' <'}</button>
                        <button className="footer-button" onClick={() => nextPage()} disabled={!canNextPage}>{' >'}</button>
                        <button className="footer-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                    </span>
                </div>


            </div>
        </Modal>
    )
}