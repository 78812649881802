import './styles/global.scss'
import './styles/main.scss'
import './styles/responsive.scss'
import 'react-toastify/dist/ReactToastify.css'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ToastContainer, Flip } from 'react-toastify';

import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { UserPage } from './pages/UserPage';
import { ClientPage } from './pages/ClientPage';
import { Projects } from './pages/Projects';
import { Finance } from './pages/Finance';
import { About } from './pages/About';
import { AuthContextProvider } from './contexts/AuthContext'
import { PreloaderOptions } from './components/PreloaderOptions';
import { useState } from 'react';
import { useAuth } from './hooks/useAuth';

function App() {

  const { userSession } = useAuth();
    //console.log("Home > localstorage", sessionStorage);
    let autenticated = false;
    if (sessionStorage.length === 0) {
        if (!userSession) {
            //console.log("Home > usuario nao autenticado");
            autenticated = true;
            //history.push('/login');
        }
    } /*else {
        console.log("Home > usuario autenticado");
        //autenticated = true;
    }*/

  const [loading, setLoading] = useState(!autenticated);

  setTimeout(() => {
    setLoading(false)
  }, 1500);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Switch>
          {
            loading ? <PreloaderOptions />
              :
              <>
                <Route path="/usuarios" component={UserPage} />
                <Route path="/clientes" component={ClientPage} />
                <Route path="/projetos" component={Projects} />
                <Route path="/financeiro" component={Finance} />
                <Route path="/institucional" component={About} />
              </>
          }
        </Switch>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/" component={Home} />
        </Switch>
        <ToastContainer transition={Flip} autoClose={4000} />
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
