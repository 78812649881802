import { useEffect, useState } from "react";
import { database } from "../services/firebase";

type Transaction = {
    id: string,
    projectId: string;
    createdDate: Number;
    description: string;
    amount: string;
    type: string;
    paymentDate: string;
    checked: boolean;
}
type Status = {
    id: string;
    projectId: string;
    createdDate: Number;
    description: string;
    deadline: string;
    deliveredDate: string;
    checked: boolean;
}
type Project = {
    id: string;
    clientId: string;
    name: string;
    address: string;
    status?: Status[];
    transactions?: Transaction[];
}
type FirebaseProject = {
    id: string;
    clientId: string;
    name: string;
    address: string;
    status: Record<string, Status>
    transactions: Record<string, Transaction>
}

export function useProjectTable(clientId: string, projectId: string) {

    const [selectedProject, setSelectedProject] = useState<Project>();

    useEffect(() => {

        if (clientId !== "" && projectId !== "") {

            const projectByIdRef = database.ref(`clients/${clientId}/projects/${projectId}`);
            projectByIdRef.on('value', project => {
                const databaseProject = project.val();
                const firebaseProject: FirebaseProject = databaseProject ?? {};

                const statusList = Object.entries(firebaseProject.status ?? {}).map(([key, value]) => {
                    return {
                        id: key,
                        projectId: value.projectId,
                        createdDate: value.createdDate,
                        description: value.description,
                        deadline: value.deadline,
                        deliveredDate: value.deliveredDate,
                        checked: value.checked,
                    }
                })

                const transactionList = Object.entries(firebaseProject.transactions ?? {}).map(([key, value]) => {
                    return {
                        id: key,
                        projectId: value.projectId,
                        createdDate: value.createdDate,
                        description: value.description,
                        amount: value.amount,
                        type: value.type,
                        paymentDate: value.paymentDate,
                        checked: value.checked,
                    }
                })

                const parsedProject = {
                    id: firebaseProject.id || projectId,
                    clientId: firebaseProject.clientId || clientId,
                    name: firebaseProject.name,
                    address: firebaseProject.address,
                    status: statusList,
                    transactions: transactionList,
                }

                setSelectedProject(parsedProject);

            })

            return () => {
                projectByIdRef.off('value'); //remove event listener for this useEffect
            }
        } 
        else {
            setSelectedProject(undefined)
        }

    }, [projectId, clientId])
    
    return selectedProject;
}