import { database } from "../services/firebase";
import { useProjectTable } from '../hooks/useProjectTable';
import '../styles/tables.scss'
import { toast } from 'react-toastify';
import { FinanceSummary } from "./FinanceSummary";
import { useAuth } from "../hooks/useAuth";
import { userProfileList } from '../utils/userProfileList';
//import checkmarkImg from '../assets/icon-checkmark.svg'
type Transaction = {
    id: string,
    projectId: string;
    createdDate: Number;
    description: string;
    amount: string;
    type: string;
    paymentDate: string;
    checked: boolean;
}
type ProjectTableProps = {
    selectedClientId: string | undefined;
    selectedProjectId: string | undefined;
    setTransactionToUpdate: (client: Transaction) => void;
}
export function FinanceTable({ selectedClientId, selectedProjectId, setTransactionToUpdate }: ProjectTableProps) {

    const { userSession } = useAuth();

    const project = useProjectTable(selectedClientId || "", selectedProjectId || "");

    function handleOnChangeCheckbox() {
    }
    async function handleCheckbox(clientId: string, transaction: Transaction) {
        const transactionsRef = database.ref(`clients/${clientId}/projects/${transaction.projectId}/transactions/${transaction.id}`);
        const checked = transaction.checked ? false : true;
        const paymentDate = new Date();
        const paymentDateString = new Intl.DateTimeFormat('pt-BR').format(paymentDate);

        await transactionsRef.update({
            //projectId: transaction.projectId,
            // createdDate: transaction.createdDate,
            //description: transaction.description,
            paymentDate: checked ? paymentDateString : "",
            checked: checked,
        })
            .then(() => {
                //console.log("did transaction locally changed ?", transaction.checked);
            })
            .catch((error) => {
                console.log(error.message);
                toast.error("Erro ao alterar status")
            });

    }
    //simple summary (deposit - withdraw = total)
    /*const summary = project?.transactions ?
        project?.transactions.reduce((accumulator, transaction) => {
            if (transaction.type === 'deposit') {
                accumulator.deposit += Number.parseFloat(transaction.amount);
                accumulator.total += Number.parseFloat(transaction.amount);
            } else {
                accumulator.withdraw += Number.parseFloat(transaction.amount);
                accumulator.total -= Number.parseFloat(transaction.amount);
            }
            return accumulator;
        }, {
            deposit: 0,
            withdraw: 0,
            total: 0,
        })
        : { deposit: 0, withdraw: 0, total: 0, }*/
    //custom summary (deposit.checked - withdraw.checked = total)
    const summary2 = project?.transactions ?
        project?.transactions.reduce((accumulator, transaction) => {
            if (transaction.checked) {
                if (transaction.type === 'deposit') {
                    accumulator.deposit += Number.parseFloat(transaction.amount);
                    accumulator.total += Number.parseFloat(transaction.amount);
                } else {
                    accumulator.withdraw += Number.parseFloat(transaction.amount);
                    accumulator.total -= Number.parseFloat(transaction.amount);
                }
            }
            return accumulator;
        }, {
            deposit: 0,
            withdraw: 0,
            total: 0,
        })
        : { deposit: 0, withdraw: 0, total: 0, }

    function handleUpdateTransaction(transaction: Transaction) {
        // The specified value "27/10/2021" does not conform to the required format, "yyyy-MM-dd"
        //          dd/mm/yyyy to yyyy-MM-dd
        if (transaction.paymentDate) {
            let convertedPaymentDate = transaction.paymentDate.split("/").reverse().join("-");
            setTransactionToUpdate({
                id: transaction.id,
                projectId: transaction.projectId,
                createdDate: transaction.createdDate,
                description: transaction.description,
                amount: transaction.amount,
                type: transaction.type,
                paymentDate: convertedPaymentDate,
                checked: transaction.checked,
            })
        } else {
            setTransactionToUpdate(transaction);
        }
    }

    function handleDeleteTransaction(clientId: string, transaction: Transaction) {
        //console.log("entered delete handle, transaction selected: ", transaction.description);
        const transactionRef = database.ref(`clients/${clientId}/projects/${transaction.projectId}/transactions/${transaction.id}`);
        transactionRef.remove()
            .then(() => {
                //console.log("transaction removed successfully!");
            })
            .catch((error) => {
                console.log(error.message);
                toast.error("Erro ao remover transação")
            });
    }

    return (
        <div className="table-project">
            {/* use {summary} for simple sum or {summary2} for sum when checked */}
            {project?.transactions?.length
                ? <FinanceSummary summary={summary2} /> : <p></p>}

            {project?.transactions?.length
                ? <div className="table-wrapper">
                    <table id="finance-table">
                        <thead>
                            <div>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Valor</th>
                                    <th>Tipo</th>
                                    <th>Data de pagamento</th>
                                    <th>Data de entrada</th>
                                    <th>
                                        <button><i className="fas fa-check"></i></button>
                                    </th>
                                    <th>
                                        <button><i className="far fa-edit"></i></button>
                                    </th>
                                    <th>
                                        <button><i className="fas fa-times"></i></button>
                                    </th>
                                </tr>
                            </div>

                            <tr></tr>
                        </thead>
                        <tbody>
                            {project.transactions.map((value, index) => {
                                return (
                                    <div>
                                        <tr key={index}>
                                            <td>{value.description}</td>

                                            <td className={value.type === 'deposit' ? 'td-deposit' : 'td-withdraw'}>
                                                {
                                                    new Intl.NumberFormat('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                    }).format(Number.parseFloat(value.amount))
                                                }
                                            </td>

                                            <td>{value.type === 'deposit' ? 'entrada' : 'saída'}</td>

                                            <td>{value.paymentDate}</td>

                                            <td>
                                                {
                                                    new Intl.DateTimeFormat('pt-BR')
                                                        .format(new Date(Number.parseInt(value.createdDate.toString())))
                                                }
                                            </td>

                                            <td className="checkbox-container" >

                                                <label className="checkbox"
                                                    hidden={/*userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]*/
                                                            userSession?.profile !== userProfileList[0]}
                                                >
                                                    <span className="checkbox__input">
                                                        <input
                                                            //disabled={userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]}
                                                            type="checkbox" name="checkbox"
                                                            checked={value.checked}
                                                            onChange={handleOnChangeCheckbox}
                                                            onClick={() => { handleCheckbox(project.clientId, value) }}
                                                        />

                                                        <span className="checkbox__control">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path fill="none" stroke="currentColor" stroke-width="3" d="M1.73 12.91l6.37 6.37L22.79 4.59" /></svg>
                                                        </span>
                                                    </span>
                                                </label>
                                            </td>

                                            <td>
                                                <button
                                                    hidden={userSession?.profile !== userProfileList[0]}
                                                    onClick={() => { handleUpdateTransaction(value) }}><i className="far fa-edit"></i>
                                                </button>
                                            </td>

                                            <td>
                                                <button
                                                    hidden={userSession?.profile !== userProfileList[0]}
                                                    onClick={() => { handleDeleteTransaction(project.clientId, value) }}><i className="fas fa-times"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </div>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <p></p>
            }

        </div>
    )
}