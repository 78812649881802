import { useEffect, useState } from "react";
import { database } from "../services/firebase";

type Project = {
    id: string;
    clientId: string;
    name: string;
    address: string;
    //status: Status[];
}
type FirebaseClient = {
    name: string;
    email: string;
    //password: string;
    profile: string;
    cnpj: string;
    projects: Record<string, Project>
}
type ClientType = {
    id: string;
    name: string;
    email: string;
    //password: string;
    profile: string;
    cnpj: string;
    projects: Project[];
}

export function useSelectedClient(clientId: string) {

    const [selectedClient, setSelectedClient] = useState<ClientType>();

    useEffect(() => {

        if (clientId !== "") {

            const clientByIdRef = database.ref(`clients/${clientId}`);
            clientByIdRef.on('value', client => {
                const databaseClient = client.val();
                const firebaseClient: FirebaseClient = databaseClient ?? {};

                const projectList = Object.entries(firebaseClient.projects ?? {}).map(([key, value]) => {

                    // const statusList = Object.entries(value.status ?? {}).map(([key, value]) => {
                    //     return {
                    //         id: key,
                    //         projectId: value.projectId,
                    //         createdDate: value.createdDate,
                    //         description: value.description,
                    //         deadline: value.deadline,
                    //         deliveredDate: value.deliveredDate,
                    //         checked: value.checked,
                    //     }
                    // })
                    return {
                        id: key,
                        clientId: clientId,
                        name: value.name,
                        address: value.address,
                      //  status: statusList,
                    }
                })

                const parsedClient = {
                    id: clientId,
                    name: firebaseClient.name,
                    email: firebaseClient.email,
                    //password: firebaseClient.password,
                    profile: firebaseClient.profile,
                    cnpj: firebaseClient.cnpj,
                    projects: projectList
                }

                setSelectedClient(parsedClient);
            })
            return () => {
                clientByIdRef.off('value'); //remove event listener for this useEffect
            }
        }

    }, [clientId])
    //console.log("useSelectedClient return: ",selectedClient);
    
    return selectedClient;
}