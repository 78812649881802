import { useState } from 'react';
import { Header } from '../components/Header'
import { PreloaderHome } from '../components/PreloaderHome';
import { Sidebar } from '../components/Sidebar'
import { useAuth } from '../hooks/useAuth'
//import { auth, firebaseSecondaryInstance, firebaseUpdateAuth } from '../services/firebase'

export function Home() {

    const { userSession } = useAuth();

    let autenticated = false;
    if (sessionStorage.length === 0) {
        if (!userSession) {
            //console.log("Home > usuario nao autenticado");
            autenticated = true;
            //history.push('/login');
        }
    } /*else {
        console.log("Home > usuario autenticado");
        console.log("Home > profile: ", userSession?.profile)
        //autenticated = true;
    }*/

    const [loading, setLoading] = useState(!autenticated);

    setTimeout(() => {
        setLoading(false)
    }, 1500);

    return (
        <div>
            {
                loading ? <PreloaderHome />
                    : <>
                        <Header />
                        <Sidebar />
                    </>
            }
        </div>
    )
}