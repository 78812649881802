import logoImg from '../assets/atlantica_transparent.png'
import '../styles/about.scss'

export function About() {
    return (
        <div className="main-content">
            <div className="about">
                <img src={logoImg} alt="logo" />

                <div className="grid">

                    <article>
                        <p>
                            SOMOS A ATLÂNTICA...
                            <br />
                            EMPRESA CRIADA E ESPECIALIZADA EM PROJETOS TÉCNICOS!
                        </p>
                    </article>

                    <div className="invisible" ></div>
                    <div className="invisible"></div>

                    <div className="vertical left">
                        <div className='arrow-left'></div>
                    </div>


                    <article>
                        <p>
                            SOMOS UMA EQUIPE DE PROFISSIONAIS HABILITADOS E CAPACITADOS, PRONTOS A ATENDER AS MAIS DIVERSAS SITUAÇÕES NAS ÁREAS: AMBIENTAIS, CIVIL, MARKETING, MINERAÇÃO E SEGURANÇA DO TRABALHO.
                        </p>
                    </article>

                    <div className='middle'>
                        <div className='arrow-right'></div>
                    </div>

                    <article>
                        <p>
                            NOSSOS TRABALHOS VISAM SATISFAZER A NECESSIDADE DO CLIENTE, BUSCANDO A MELHOR FORMA SUSTENTÁVEL E FINANCEIRA DE CUMPRIR A DEMANDA, RESPEITANDO AS LEGISLAÇÕES VIGENTES E SUAS DIRETRIZES.
                        </p>
                    </article>

                    <div className="vertical right">
                        <div className='arrow-right'></div>
                    </div>

                    <article>
                        <p>
                            TEMOS PROJETOS APROVADOS EM DIVERSOS ESTADOS, ALÉM DE POSSUIR UMA EQUIPE DE COLABORADORES E PRESTADORES DE SERVIÇO DAS MAIS DIVERSAS ÁREAS, COMO:
                            <br />
                            ENGENHEIRO AMBIENTAL, ENGENHEIRO CIVIL, ENGENHEIRO DE MINAS, ENGENHEIRO AGRIMENSOR, ENGENHEIRO QUÍMICO, ENGENHEIRO ELÉTRICO, ENGENHEIRO DE SEGURANÇA DO TRABALHO, ARQUITETO, TOPÓGRAFO, ADMINISTRADORES E OUTROS.
                        </p>
                    </article>


                    <div className='middle'>
                        <div className='arrow-left'></div>
                    </div>

                    <article>
                        <p>
                            HOJE CONTAMOS COM MAIS DE 10 CONTRATOS MENSAIS E UMA CARTEIRA DE 150 CLIENTES.
                        </p>
                    </article>

                    <div className="vertical left">
                        <div className='arrow-left'></div>
                    </div>

                    <article>
                        <p>
                            TODA ESSA ESTRUTURA FOI MONTADA COM O INTUÍDO DE PRESERVAR A INTEGRIDADE DOS EMPREENDIMENTOS DOS NOSSOS CLIENTES, FORNECER RECURSOS PARA O DESENVOLVIMENTO SOCIAL, ECONÔMICO, AMBIENTAL E AGRÍCOLA, COMO TAMBÉM
                            ABASTECER A ALTA DEMANDA REGIONAL EM BUSCA DE NOVOS PROJETOS E UMA EQUIPE QUALIFICADA PARA PLANEJAR, PROJETAR E EXECUTAR.
                        </p>
                    </article>

                    <div className='middle'>
                        <div className='arrow-right'></div>
                    </div>

                    <article>
                        <p>
                            FAÇA SEU ORÇAMENTO SEM COMPROMISSO...
                            <br />
                            VENHA CONHECER NOSSOS IDEAIS, NOSSA HISTÓRIA E NOSSAS APROVAÇÕES.
                            <br />
                            FORNECEMOS AS MELHORES TECNOLOGIAS E SOLUÇÕES PARA SEUS PROJETOS.
                        </p>
                    </article>
                </div>

                <br /><br />
                <div className="contact">
                    {/*
                    <a href="www.google.com/search?q=atlanticagestaoeconsultoria" target="_blank"><i className="fab fa-google"></i></a>
                    <a href="https://www.facebook.com/atlanticagestaoeconsultoria/" target="_blank"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://www.instagram.com/atlanticagestaosustentavel/" target="_blank"><i className="fab fa-instagram"></i></a>
                    <div><i className="fab fa-google"></i></div>
                    <div><i className="fab fa-facebook-f"></i></div>
                    */}
                    <a target="_blank" href="https://www.instagram.com/atlanticagestaosustentavel/">
                        <div><i className="fab fa-instagram"></i></div>
                    </a>
                    <div><b>Atlântica Gestão Sustentável</b></div>
                </div>
                <div className="contact">
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=5519991487142">
                        <div><i className="fab fa-whatsapp"></i></div>
                    </a>
                    <div><b>(19) 99148-7142</b></div>
                </div>

                <div className="contact">
                    <div><i className="far fa-envelope"></i></div>
                    <div><b>lucas.gestaosustentavel@gmail.com</b></div>
                </div>

                <div className="contact">
                    <div><p>Rua Alberto de Souza, 155 - Centro, Estiva Gerbi/SP</p></div>
                </div>
            </div>
        </div>
    )
}