import { useEffect, useState } from "react";
import { database } from "../services/firebase";

type Project = {
    id: string;
    name: string;
    address: string;
}
type FirebaseClients = Record<string, {
    name: string;
    email: string;
    password: string;
    profile: string;
    cnpj: string;
    address: string;
    contact: string;
    projects?: Record<string, Project>
}>
type ClientType = {
    id: string;
    name: string;
    email: string;
    password: string;
    profile: string;
    cnpj: string;
    address: string;
    contact: string;
    projects?: Project[];
}

export function useClientTable() {

    const [ clientList, setClientList ] = useState<ClientType[]>([]);

    useEffect(() => {
        const clientsRef = database.ref('clients');
        clientsRef.on('value', clients => {
            const databaseClients = clients.val();
            const firebaseClients: FirebaseClients = databaseClients ?? {};
            
            const parsedClientList = Object.entries(firebaseClients).map(([key, value]) => {
                //desconstruindo projetos
                const projectList = Object.entries(value.projects ?? {}).map(([key, value]) => { 
                    return {
                        id: key,
                        name: value.name,
                        address: value.address,
                    }
                })
                return {
                    id: key,
                    name: value.name,
                    email: value.email,
                    password: value.password,
                    profile: value.profile,
                    cnpj: value.cnpj,
                    address: value.address,
                    contact: value.contact,
                    projects: projectList
                }
            })
            setClientList(parsedClientList);
        })

        return () => {
            clientsRef.off('value'); //remove event listener for this useEffect
        }
    }, [])
    //console.log(clientList);
    
    return clientList;
}