import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { userProfileList } from '../utils/userProfileList';

var instance = 0;

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
}

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const database = firebase.database();

function firebaseSecondaryInstance() {
    instance++;
    var secondaryFirebase = firebase.initializeApp(firebaseConfig, "U_appInstance_" + instance);
    //console.log(secondaryFirebase.name);
    return secondaryFirebase;
}

type Credentials = {
    //uid: string;
    email: string;
    password: string;
}
async function firebaseUpdateAuth(userId: string | undefined, profile: string,
    userToUpdate: Credentials, newCredentials: Credentials) {

    //let credentialUpdated: boolean = false;

    const node = profile !== userProfileList[2] ? "users" : "clients"

    const secondaryInstance = firebaseSecondaryInstance();

    const credentialUpdated = await secondaryInstance.auth()
        .signInWithEmailAndPassword(userToUpdate?.email, userToUpdate?.password)
        .then( async () => { //if it was possible to authenticate, proceed to update credentials

            const currentUser = secondaryInstance.auth().currentUser;

            let pwUpdated = undefined;
            if (userToUpdate?.password !== newCredentials.password) {
                pwUpdated = await currentUser?.updatePassword(newCredentials.password)
                    .then(() => {
                        const userRef = database.ref(`${node}/${userId}`);
                        userRef.update({ password: newCredentials.password })
                        //console.log("pw credentialUpdated true")
                        return true
                    })
                    .catch((error) => {
                        console.log(error.message);
                        return false
                    });
            }

            let emailUpdated = undefined;
            if (userToUpdate?.email !== newCredentials.email) {

                emailUpdated = await currentUser?.updateEmail(newCredentials.email)
                    .then(() => {
                        const authRef = database.ref('auth/' + userId);
                        const userRef = database.ref(`${node}/${userId}`);
                        authRef.update({ email: newCredentials.email })
                        userRef.update({ email: newCredentials.email })
                        //console.log("email credentialUpdated true")
                        return true
                    })
                    .catch((error) => {
                        console.log(error.message);
                        return false
                    });
            }
            secondaryInstance.auth().signOut();
            return (pwUpdated || emailUpdated);
        })
        .catch((error) => {
            console.log(error.message)
            return false;
        });

    return credentialUpdated;
}


export { firebase, auth, database, firebaseSecondaryInstance, firebaseUpdateAuth }