import { database } from "../services/firebase";
import { useProjectTable } from '../hooks/useProjectTable';
import '../styles/tables.scss'
import { toast } from 'react-toastify';
import { useAuth } from "../hooks/useAuth";
import { userProfileList } from '../utils/userProfileList';
import { useState } from "react";
//import checkmarkImg from '../assets/icon-checkmark.svg'

type Status = {
    id: string;
    projectId: string;
    createdDate: Number;
    description: string;
    deadline: string;
    deliveredDate: string;
    checked: boolean;
}
type Project = {
    id: string;
    clientId: string;
    name: string;
    address: string;
    status?: Status[];
}
type ProjectTableProps = {
    selectedClientId: string | undefined;
    selectedProjectId: string | undefined;
    setStatusToUpdate: (client: Status) => void;
}
export function ProjectTable({ selectedClientId, selectedProjectId, setStatusToUpdate }: ProjectTableProps) {

    const { userSession } = useAuth();
    
    const project = useProjectTable(selectedClientId || "", selectedProjectId || "");

    function handleOnChangeCheckbox() {
    }

    async function handleCheckbox(clientId: string, status: Status) {
        /*console.log("checked - clientId: ", clientId);
        console.log("checked - projectId: ", status.projectId);
        console.log("checked - statusId: ", status.id);*/

        const statusRef = database.ref(`clients/${clientId}/projects/${status.projectId}/status/${status.id}`);

        const checked = status.checked ? false : true;

        const deliveredDate = new Date();
        const deliveredDateString = new Intl.DateTimeFormat('pt-BR').format(deliveredDate);

        await statusRef.update({
            projectId: status.projectId,
            createdDate: status.createdDate,
            description: status.description,
            deadline: status.deadline,
            deliveredDate: checked ? deliveredDateString : "",
            checked: checked,
        })
            .then(() => {
                //console.log("did status locally changed ?", status.checked);
            })
            .catch((error) => {
                console.log(error.message);
                toast.error("Erro ao alterar status")
            });

    }

    function handleUpdateStatus(status: Status) {
        setStatusToUpdate(status);
    }

    function handleDeleteStatus(clientId: string, status: Status) {
        //console.log("entered delete handle, status selected: ", status.description);
        const statusRef = database.ref(`clients/${clientId}/projects/${status.projectId}/status/${status.id}`);
        statusRef.remove()
            .then(() => {
                //console.log("status removed successfully!");
            })
            .catch((error) => {
                console.log(error.message);
                toast.error("Erro ao remover status")
            });
    }

    return (
        <div className="table-project">

            {project?.status?.length
                ? <div className="table-wrapper">
                    <table>
                        <thead>
                            <div>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Prazo de entrega</th>
                                    <th>Data de conclusão</th>
                                    <th>Data de entrada</th>
                                    <th>
                                        <button><i className="fas fa-check"></i></button>
                                    </th>
                                    <th>
                                        <button><i className="far fa-edit"></i></button>
                                    </th>
                                    <th>
                                        <button><i className="fas fa-times"></i></button>
                                    </th>
                                </tr>
                            </div>

                            <tr></tr>
                        </thead>
                        <tbody>
                            {project.status.map((value, index) => {
                                return (
                                    <div>
                                        <tr key={index}>
                                            <td>{value.description}</td>
                                            <td>{value.deadline}</td>
                                            <td>{value.deliveredDate}</td>
                                            <td>{new Intl.DateTimeFormat('pt-BR').format(new Date(Number.parseInt(value.createdDate.toString())))}
                                            </td>

                                            <td className="checkbox-container" >

                                                {/*<input type="checkbox" /><span className="checkmark"></span>
                                                <button><i className="fas fa-check"></i></button>
                                                <img src={checkmarkImg} alt="" />*/}

                                                <label className="checkbox"
                                                    hidden={userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]}
                                                >
                                                    <span className="checkbox__input">
                                                        <input
                                                            //disabled={userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]}
                                                            type="checkbox" name="checkbox"
                                                            checked={value.checked}
                                                            onChange={handleOnChangeCheckbox}
                                                            onClick={() => { handleCheckbox(project.clientId, value) }}
                                                        />

                                                        <span className="checkbox__control">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path fill="none" stroke="currentColor" stroke-width="3" d="M1.73 12.91l6.37 6.37L22.79 4.59" /></svg>
                                                        </span>
                                                    </span>
                                                </label>
                                            </td>

                                            <td>
                                                <button
                                                    hidden={userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]}
                                                    onClick={() => { handleUpdateStatus(value) }}><i className="far fa-edit"></i>
                                                </button>
                                            </td>

                                            <td>
                                                <button
                                                    hidden={userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]}
                                                    onClick={() => { handleDeleteStatus(project.clientId, value) }}><i className="fas fa-times"></i>
                                                </button>
                                            </td>

                                        </tr>
                                    </div>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <p></p>
            }

        </div>
    )
}