import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { auth } from '../services/firebase'
import { toast } from 'react-toastify';
import logoImg from '../assets/atlantica_transparent.png'
import logoCroppedImg from '../assets/atlantica_transparent_cropped.png'
import '../styles/login.scss'
import { useState } from 'react';
import { RecoverPasswordModal } from '../components/RecoverPasswordModal';
import Modal from 'react-modal'
Modal.setAppElement('#root')

type SignType = {
    email: string;
    password: string;
    rememberMe: boolean;
}

export function Login() {

    let [rememberMe, setRembemberMe] = useState(true);
    //sessionStorage.setItem('rememberme', "true");

    const history = useHistory();

    function navigateToHome() {
        history.push('/');
        //setTimeout(() => { history.push('/'); }, 1000);
    }

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<SignType>();

    async function onSubmit(userSign: SignType) {
        const signInResult = await auth.signInWithEmailAndPassword(userSign.email, userSign.password)
            .catch((error) => {
                if (error.code === 'auth/wrong-password') {
                    toast.error('Senha inválida');
                } else if (error.code === 'auth/invalid-email') {
                    toast.error('E-mail Inválido');
                } else if (error.code === 'auth/user-not-found') {
                    toast.error('Usuário não encontrado');
                } else {
                    toast.error('Não foi possível realizar login');
                }
                console.log("Error: ", error.message)
            });

        if (signInResult?.user) {
            navigateToHome();
        }

    }

    function handleOnChangeRememberMe() {
        let remember = !rememberMe;
        setRembemberMe(remember)
        console.log("remember checked: ", remember)
        sessionStorage.setItem('rememberme', remember ? "true" : "false");
    }

    function handleResetPassword() {
        var sendEmailAddress = prompt("Para recuperar sua senha, informe o email cadastrado:", "");
        if (sendEmailAddress != null) {
            auth.sendPasswordResetEmail(sendEmailAddress ? sendEmailAddress : "").then(function () {
                toast.success("Link para recuperação de senha enviado!", { className: "toast-success" })
            }).catch(function (error) {
                console.log("An error happened... ", sendEmailAddress);
            });
        }
    }

    //handling client modal
    const [isRecoverPasswordModalOpen, setIsRecoverPasswordModalOpen] = useState(false);
    function handleOpenRecoverPasswordModal() {
        setIsRecoverPasswordModalOpen(true);
    }
    function handleCloseRecoverPasswordModal() {
        setIsRecoverPasswordModalOpen(false);
    }
    return (
        <div id="page-auth">

            <main>
                <div className="main-login">
                    <img src={logoCroppedImg} alt="Atlântica" />
                    <div className="separator">Informe as credenciais abaixo:</div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <label htmlFor="uname"><b>Usuário</b></label> */}
                        <input type="text" placeholder="Informe o usuário" id="user-id" required
                            {
                            ...register("email", { required: true, })
                            }
                        />

                        {/* <label htmlFor="psw"><b>Senha</b></label> */}
                        <input type="password" placeholder="Senha" id="password" required
                            {
                            ...register("password", {
                                required: true,
                                minLength: {
                                    value: 6,
                                    message: "invalid password"
                                }
                            })
                            }
                        />

                        <span><a href="#" onClick={handleOpenRecoverPasswordModal}>Esqueceu a senha ?</a></span>
                        <button
                            className="login-btn" type="submit" id="signIn"
                        >
                            Entrar
                        </button>

                        <div className="remember-me">
                            <input type="checkbox" name="remember" checked={rememberMe} onChange={handleOnChangeRememberMe} />
                            <p>Lembre de mim</p>
                        </div>

                    </form>
                </div>
            </main>

            <aside>
                <img src={logoImg} alt="Logo Atlântica" />
            </aside>

            <RecoverPasswordModal
                isOpen={isRecoverPasswordModalOpen}
                onRequestClose={handleCloseRecoverPasswordModal}
            />

        </div>
    )
}