import incomeImg from '../assets/income.svg'
import outcomeImg from '../assets/outcome.svg'
import totalImg from '../assets/total.svg'
import '../styles/summary.scss'

type FinanceSummaryType = {
    summary: SummaryType
}
type SummaryType = {
    deposit: number;
    withdraw: number;
    total: number;
}
export function FinanceSummary({ summary }: FinanceSummaryType) {
    return (
        <div className="summary">
            <div>
                <header>
                    <p>Entradas</p>
                    <img src={incomeImg} alt="Entradas" />
                </header>
                <strong>
                    {
                        new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(summary.deposit)
                    }
                </strong>
            </div>
            <div>
                <header>
                    <p>Saídas</p>
                    <img src={outcomeImg} alt="Saídas" />
                </header>
                <strong>-
                    {
                        new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(summary.withdraw)
                    }
                </strong>
            </div>
            <div className="highlight-background">
                <header>
                    <p>Total</p>
                    <img src={totalImg} alt="Total" />
                </header>
                <strong>
                    {
                        new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        }).format(summary.total)
                    }
                </strong>
            </div>
        </div>
    )
}