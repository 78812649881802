import ReactLoading from 'react-loading';
import '../styles/loading.scss'

export function PreloaderHome() {
    
    return (
        <>
            <ReactLoading type={"spin"} color={"#38b498"} height={'5%'} width={'5%'} className="loading-home" />
        </>
    )
}

/* references:
    https://www.npmjs.com/package/react-loading
    https://www.npmjs.com/package/react-lottie
*/