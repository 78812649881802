
import { auth } from '../services/firebase';
import logoCroppedImg from '../assets/atlantica_transparent_cropped.png'

import '../styles/header.scss'
import { useAuth } from '../hooks/useAuth';

export function Header() {

    const { userSession } = useAuth();
    //const userProfile = sessionStorage.getItem('profile')
    const userDisplayName = userSession?.name ? userSession?.name : sessionStorage.getItem('name');

    function navigateToLogin() {
        
        auth.signOut()
            .then(() => {
                //console.log("Header > remember ?", sessionStorage.getItem('rememberme'));

                if (sessionStorage.getItem('rememberme') !== 'true') {
                    sessionStorage.clear()
                }
                //history.push('/login');
                // setTimeout(() => { history.push('/login'); }, 1000);//your code to be executed after x milliseconds 
            })
    }

    return (
        <div className="header-position">
            <header className="header-container" id="header">
                <img src={logoCroppedImg} alt="Atlântica" />
                <p>Atlântica Gestão Sustentável</p>
                <b className="display-user">Olá, {userDisplayName}</b>
                <button id="btn-logout-head" onClick={navigateToLogin}>Sair</button>
            </header>
        </div>
    )
}