import { database } from '../services/firebase'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { userProfileList } from '../utils/userProfileList';

import '../styles/forms.scss'
import '../styles/search.scss'

import { ProjectTable } from './ProjectTable';
import { useSelectedClient } from '../hooks/useSelectedClient';
import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';

type StatusForm = {
    client: string;
    project: string;
    description: string;
    deadline: string;
}
type Status = {
    id: string,
    projectId: string;
    createdDate: Number;
    description: string;
    deadline: string;
    deliveredDate: string;
    checked: boolean;
}
type Project = {
    id: string;
    clientId: string;
    name: string;
    address: string;
    status?: Status[];
}
type ClientType = {
    id: string;
    name: string;
    email: string;
    //password: string;
    profile: string;
    cnpj: string;
    //projects: Project[];
}

interface ProjectProps {
    onOpenSelectClientModal: () => void;
    onOpenCreateProjectModal: (project: Project | undefined) => void;
    selectedClient?: ClientType;
}

export function ProjectForm({ onOpenSelectClientModal, onOpenCreateProjectModal, selectedClient }: ProjectProps) {

    const { userSession } = useAuth();

    const [updating, setUpdating] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue, setFocus } = useForm<StatusForm>();

    const [statusToUpdate, setStatusToUpdate] = useState<Status>();

    const [updatingStatus, setUpdatingStatus] = useState<Status>();

    let currentClientId = "";
    if (userSession?.profile === userProfileList[2]) {
        currentClientId = userSession?.id ?? ""
    } else {
        currentClientId = selectedClient?.id ?? ""
    }
    const client = useSelectedClient(currentClientId);

    setValue('client', client?.name ?? "");

    const [projectOption, setProjectOption] = useState<Project>();

    function handleSelectedProjectOption(option: string) {
        console.log("ProjectForm > handleSelectedProjectOption > option: ", option)
        setProjectOption(client?.projects.find((project, index) => project.name === option));
        
        setStatusToUpdate(undefined)
    }

    if( projectOption ) {
        if( selectedClient ) {
            if( selectedClient.id !== projectOption?.clientId ) {
                setProjectOption(undefined);
            }
        }
    }

    async function onSubmit(status: StatusForm) {

        let newDeadlineDate = new Date(status.deadline);
        //fixing date if browser mess it up!!
        try {
            const originalDeadlineDayNum = Number.parseInt(status.deadline.substr(8, 2));
            const newDeadlineDayNum = Number.parseInt(new Intl.DateTimeFormat('pt-BR').format(newDeadlineDate).substr(0, 2))
            if (originalDeadlineDayNum === newDeadlineDayNum) {
                // console.log("same day")
            } else if (originalDeadlineDayNum > newDeadlineDayNum) {
                // console.log("new day is lesser")
                newDeadlineDate = new Date(newDeadlineDate.setDate(newDeadlineDate.getDate() + 1))
            } else if (originalDeadlineDayNum < newDeadlineDayNum) {
                // console.log("new day is greater")
                newDeadlineDate = new Date(newDeadlineDate.setDate(newDeadlineDate.getDate() - 1))
            }
        } catch (error) { console.log(error.message); }

        let newDeadlineDateString = new Intl.DateTimeFormat('pt-BR').format(newDeadlineDate);

        if (!updating) {
            let createDate = new Date();
            let createDateNumber = Date.parse(createDate.toString());
            //let createDateString = new Intl.DateTimeFormat('pt-BR').format(new Date());  
            //console.log("project form > create date: " + createDateStringNew + " - " + createDateNumber )

            let projectId = status.project;
            if (client?.projects) {
                client.projects.map((project, index) => {
                    if (project.name === status.project) {
                        projectId = project.id;
                    }
                })
            }
            const newStatus: Omit<Status, 'id'> = {
                projectId: projectId,
                createdDate: createDateNumber,
                description: status.description,
                deadline: newDeadlineDateString,
                deliveredDate: "",
                checked: false,
            }

            const clientRef = database.ref(`clients/${client?.id}/projects/${projectId}/status`);
            clientRef.push(newStatus).then(() => {
                handleClearForm()
                toast.success("Status criado com sucesso!", { className: "toast-success" })
            }).catch((error) => {
                console.error("Error: ", error.message);
                toast.error("Erro ao criar status")
            })

        } else {

            const statusRef = database.ref(`clients/${client?.id}/projects/${updatingStatus?.projectId}/status/${updatingStatus?.id}`);
            statusRef.update({
                //projectId: updatingStatus?.projectId,
                //createdDate: updatingStatus?.createdDate,
                description: status.description,
                deadline: newDeadlineDateString,
                deliveredDate: updatingStatus?.deliveredDate,
                checked: updatingStatus?.checked,
            }).then(() => {
                handleClearForm()
                toast.success("Status atualizado com sucesso!", { className: "toast-success" })
            }).catch((error) => {
                console.error("Error: ", error.message);
                toast.error("Erro ao editar status")
            })

        }
    }

    function handleClearForm() {
        setValue('description', '');
        setValue('deadline', '');
        //updating = false;
        setUpdating(false);
        setStatusToUpdate(undefined)
    }

    function formatDeadlineDate(date: string) {
        try {/*    dd/mm/yyyy 
                   0123456789    */
            var day = date.substr(0, 2), month = date.substr(3, 2), year = date.substr(6, 4);
            //console.log([year, month, day].join('-'));
            return [year, month, day].join('-');
        } catch (error) {
            console.log(error.message);
        }
    }

    if (statusToUpdate && !updating) {
        //updating = true;
        setValue('description', statusToUpdate.description)
        const deadlineFormatted = formatDeadlineDate(statusToUpdate.deadline);
        setValue('deadline', deadlineFormatted ? deadlineFormatted : '');
        setFocus('description');
        setUpdating(true);
        setUpdatingStatus(statusToUpdate);
        setStatusToUpdate(undefined)
    }
    if (selectedClient && !updating && !projectOption) {
        setValue('project', "")
    }

    return (
        <div>
            {/* {console.log("rendering form...")} */}
            <div className="forms">
                <h3>
                    Selecionar Cliente
                </h3>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="search-form">
                        <label htmlFor=""><b>Cliente</b></label>

                        {(userSession?.profile === userProfileList[0] || userSession?.profile === userProfileList[1])
                            ?
                            <input type="text" placeholder="selecione um cliente" readOnly={true}
                                //onClick={userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]}
                                onClick={onOpenSelectClientModal}
                                {
                                ...register("client", { required: true, })
                                }
                            />
                            :
                            <input type="text" placeholder="selecione um cliente" readOnly={true}
                                {
                                ...register("client", { required: true, })
                                }
                            />
                        }
                        <button
                            hidden={userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]}
                            type="button"
                            onClick={onOpenSelectClientModal}
                        ><i className="fas fa-search"></i></button>
                    </div>

                    <div className="error-message">
                        <span></span><strong>{errors.client?.type === 'required' && "cliente não informado"}</strong>
                    </div>

                    <div className="search-form">
                        <label htmlFor="project"><b>Projetos</b></label>
                        <select
                            {
                            ...register("project", {
                                required: true,
                                pattern: {
                                    value: /^(?!\s*$).+/,
                                    message: "selecionar um projeto"
                                }
                            })}
                            onChange={(e) => handleSelectedProjectOption(e.target.value)}
                        >
                            <option value=""> -- novo projeto -- </option>
                            {
                                client?.projects
                                    ? client.projects.map((project, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={project.name}
                                            >
                                                {project.name}
                                            </option>
                                        )
                                    })
                                    : console.log("no projects available")
                            }
                        </select>

                        <button type="button"
                            hidden={userSession?.profile !== userProfileList[0] && userSession?.profile !== userProfileList[1]}
                            onClick={() => { onOpenCreateProjectModal(projectOption) }}
                        >
                            {projectOption?.id
                                ? <i className="far fa-edit"></i>
                                : <i className="far fa-plus-square"></i>}
                            {/*  <i className="fas fa-plus-square"></i> */}
                            {/*  <i className="fas fa-plus"></i>  */}
                            {/*  <i className="fas fa-plus-circle"></i>  */}
                            {/*  <i className="fas fa-folder-plus"></i>  */}
                        </button>
                    </div>

                    <div className="error-message">
                        <span></span><strong>{errors.project?.type === 'required' && "projeto não informado"}</strong>
                    </div>

                    {/*-----------------INSERIR STATUS--------------------------------------------------------------------------------*/}
                    {(userSession?.profile === userProfileList[0] || userSession?.profile === userProfileList[1])
                        ?
                        <>
                            <h3>Inserir Status</h3>
                            <div>
                                <label htmlFor=""><b>Status</b></label>
                                <textarea id="project-status" rows={3}
                                    {
                                    ...register("description", { required: true, })
                                    }
                                ></textarea>
                            </div>
                            <div className="error-message">
                                <span></span>
                                <strong>{errors.description?.type === 'required' && "status não informado"}</strong>
                            </div>

                            <div>
                                <label htmlFor=""><b>Prazo</b></label>
                                <input id="deadline-status" type="date" placeholder="digite o nome do usuário"
                                    {
                                    ...register("deadline", { required: true, })
                                    }
                                />

                            </div>
                            <div className="error-message">
                                <span></span>
                                <strong>{errors.deadline?.type === 'required' && "prazo não informado"}</strong>
                            </div>

                            <div>
                                <div className="btn-container">
                                    <button className="btn-save" type="submit">
                                        {updating ? "Alterar" : "Cadastrar"}
                                    </button>
                                    <button className="btn-cancel" type="button" onClick={handleClearForm}>Limpar</button>
                                </div>

                            </div>
                            <div className="error-message">
                                <span></span><strong></strong>
                            </div>
                        </>
                        : ""
                    }

                </form>
            </div>

            <ProjectTable
                selectedProjectId={projectOption?.id}
                selectedClientId={projectOption?.clientId}
                setStatusToUpdate={setStatusToUpdate}
            />
        </div>
    )
}