import { useEffect, useState } from "react";
import { database } from "../services/firebase";

type FirebaseUsers = Record<string, {
    name: string;
    email: string;
    password: string;
    profile: string;
}>

type UserType = {
    id: string;
    name: string;
    email: string;
    password: string;
    profile: string;
}

export function useUserTable() {

    //const user = userAuth
    const [ userList, setUserList ] = useState<UserType[]>([]);

    useEffect(() => {
        const usersRef = database.ref('users');
        usersRef.on('value', users => {
            const databaseUsers = users.val();
            const firebaseUsers: FirebaseUsers = databaseUsers ?? {};
            
            const parsedUserList = Object.entries(firebaseUsers).map(([key, value]) => {
                return {
                    id: key,
                    name: value.name,
                    email: value.email,
                    password: value.password,
                    profile: value.profile,
                }
            })

            setUserList(parsedUserList);
        })

        return () => {
            usersRef.off('value'); //remove event listener for this useEffect
        }
    }, [])

    return userList;
}