import { useState } from "react";
import { FinanceForm } from "../components/FinanceForm";
import { SelectClientModal } from '../components/SelectClientModal';
import Modal from 'react-modal'

Modal.setAppElement('#root')

type Project = {
    id: string;
    clientId: string;
    name: string;
    address: string;
}
type ClientType = {
    id: string;
    name: string;
    email: string;
    //password: string;
    profile: string;
    cnpj: string;
    projects?: Project[];
}

export function Finance() {
    //handling client modal
    const [isSelectClientModalOpen, setIsSelectClientModalOpen] = useState(false);
    function handleOpenSelectClientModal() {
        setIsSelectClientModalOpen(true);
    }
    function handleCloseSelectClientModal() {
        setIsSelectClientModalOpen(false);
    }

    //passing selected client from client modal
    const [selectedClient, setSelectedClient] = useState<ClientType>();

    return (
        <div className="main-content">
            <FinanceForm
                onOpenSelectClientModal={handleOpenSelectClientModal}
                selectedClient={selectedClient}
            />
            <SelectClientModal
                isOpen={isSelectClientModalOpen} 
                onRequestClose={handleCloseSelectClientModal} 
                setSelectedClient={setSelectedClient}
            ></SelectClientModal>
        </div>
    )
}