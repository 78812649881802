import { useState } from "react";
import { ProjectForm } from "../components/ProjectForm";
import { SelectClientModal } from '../components/SelectClientModal';
import { CreateProjectModal } from '../components/CreateProjectModal';
import Modal from 'react-modal'

Modal.setAppElement('#root')

type Project = {
    id: string;
    clientId: string;
    name: string;
    address: string;
}
type ClientType = {
    id: string;
    name: string;
    email: string;
    //password: string;
    profile: string;
    cnpj: string;
    projects?: Project[];
}

export function Projects() {

    //handling client modal
    const [isSelectClientModalOpen, setIsSelectClientModalOpen] = useState(false);
    function handleOpenSelectClientModal() {
        setIsSelectClientModalOpen(true);
    }
    function handleCloseSelectClientModal() {
        setIsSelectClientModalOpen(false);
    }

    //handling project modal
    const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
    //const [isEditingProject, setIsEditingProject] = useState(false);
    const [selectedProject, setSelectedProject] = useState<Project>();

    function handleOpenCreateProjectModal(project: Project | undefined) {
        setIsCreateProjectModalOpen(true);
        //setIsEditingProject(isEditing);
        setSelectedProject(project);
    }
    function handleCloseCreateProjectModal() {
        setIsCreateProjectModalOpen(false);
    }
    
    //passing selected client from client modal
    const [selectedClient, setSelectedClient] = useState<ClientType>();
    
    return (
        <div className="main-content">
            <ProjectForm 
                onOpenSelectClientModal={handleOpenSelectClientModal} 
                onOpenCreateProjectModal={handleOpenCreateProjectModal} 
                selectedClient={selectedClient}
            />
            
            <SelectClientModal
                isOpen={isSelectClientModalOpen} 
                onRequestClose={handleCloseSelectClientModal} 
                setSelectedClient={setSelectedClient}
            ></SelectClientModal>
            <CreateProjectModal
                isOpen={isCreateProjectModalOpen} 
                selectedProject={selectedProject}
                clientId={selectedClient?.id}
                clientName={selectedClient?.name}
                onRequestClose={handleCloseCreateProjectModal} 
                
            ></CreateProjectModal>
        </div>
    )
}